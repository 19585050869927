import NavbarServices from '../../components/Navbar/NavbarServices';
import '../textile/styles.css';
import image1 from '../../assets/images/label_pack-img1.jpg';
import image2 from '../../assets/images/label_pack-img2.jpg';
import image3 from '../../assets/images/label_pack-img3.jpg';
import image4 from '../../assets/images/label_pack-img4.jpg';
import image5 from '../../assets/images/label_pack-img5.jpg';
import image6 from '../../assets/images/label_pack-img6.jpg';
import fsc from '../../assets/icons/fsc.png';
import oekotex from '../../assets/icons/oekotex.png';
import gots from '../../assets/icons/gots.png';
import grs from '../../assets/icons/grs.png';
import logo_letna from '../../assets/images/Logo_letna.png';
import Modal from '../../components/Modal/Modal';
import React, { useState } from 'react';


const Labelandpack = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const images = [
    { src: image1, project: 'Cardboard Box', description: 'Collection Basics' },
    { src: image2, project: 'Cardboard Box', description: 'Collection Premium' },
    { src: image3, project: 'All Over Print Box', description: 'American Socks' },
    { src: image4, project: 'Premium Book Box', description: 'Letna Collection' },
    { src: image5, project: 'Wine Kraft Bag', description: 'Paço Vedro' },
    { src: image6, project: 'Premium Box', description: 'Paz' },
  ];

  const openModal = (image) => {
    
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <NavbarServices />
      <div className='margens-services'>
        
        <div>
          <div className='card-content'>
            <div className='row-1'>
              {images.map((image, index) => (
                <div className='card-image' key={index} onClick={() => openModal(image.src)}>
                  <img src={image.src} alt={`Imagem ${index + 1}`}  />
                  <div className='description'>
                    <h3>{image.project}</h3>
                    <p>{image.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div class="label-pack-section">
          <h1 class="title">Label & Pack</h1>
          <p class="intro">
              Our expertise in premium boxes is our latest achievement, and initial tests have successfully convinced the top brands in the market. 
              These rigid, thick, and robust boxes offer an unmatched level of quality, perfect for satisfying the high demands of the luxury world. 
              Whether for gift sets or elegant high-end packaging, these premium boxes will please the most discerning customers in fashion, jewelry, or hospitality.
          </p>
          
          <h2 class="subtitle">Items:</h2>
          <ul class="accessory-list">
              <li>Shipping Boxes</li>
              <li>Gifting Packaging</li>
              <li>Influencer Boxes</li>
          </ul>

          <p class="description1">
              We produce 100% custom ribbons and stickers that stand out for their diversity and quality. 
              Our weaving machines craft ribbons in various materials, colors, and patterns, ideal for event wristbands, gift wrapping, and more. 
              Our advanced printing technology allows us to produce stickers in any finish, on sheets or rolls, in any shape and size.
          </p>

          <h2 class="subtitle">Type of Items:</h2>
          <ul class="accessory-list" style={{marginBottom:'50px'}}>
              <li>Product Packaging</li>
              <li>Bracelets for Special Events</li>
              <li>Lace Closures</li>
              <li>Decorative Stickers</li>
          </ul>
        </div>

      </div>
      
      <footer className='footer'>
        <div className='margens'>
          <div className='row-1'>
            <div className='páginas site-links'>
              <h4>SITE LINKS</h4>
              <a href="#home">Home</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
              <a href="#contact">Contacts</a>
            </div>
            <div className='páginas'>
              <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos<br/>V.N. Famalicão | Portugal</p>   
              
            </div>
            <div className='páginas'>
              <p>+351 253 131 515<br/>comercial@letna.com<br/>www.letna.pt</p>           
              
            </div>
            <div className='logos'>
                <img src={logo_letna} alt="Logo" style={{width:'150px', alignItems:'center', justifyContent:'center'}}/>
            </div>
          </div>
          <hr></hr>
          <div className='certifications'>
              <p>Certifications</p>
              <img  className='certificado' src={fsc} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
          <div className='row2'>
            <p>Copyright © 2024 Todos os direitos reservados | Política de privacidade</p>
          </div>
        </div>
      </footer>

      <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
    </>
  );
};



export default Labelandpack;